export const navbar = [
    {
        id: 0,
        menu: "Our Brand",
        total: 1,
        list: [{
            id: 0,
            category: "OUR BRANDS",
            menuList: [
                {
                    name: "PIERRE UNO",
                    link: ""
                },
                {
                    name: "JORDAN CAVALLI",
                    link: ""
                },
                {
                    name: "BEYONDSKIN",
                    link: ""
                },
                {
                    name: "RUDES & SON",
                    link: ""
                },
                {
                    name: "ARROW APPLE",
                    link: ""
                },
                {
                    name: "CATASY",
                    link: ""
                },
                {
                    name: "PIERRE UNO KIDS",
                    link: ""
                },
                {
                    name: "FINY GIRLS",
                    link: ""
                },
            ]
        }]
    },
    {
        id: 0,
        menu: "MEN",
        total: 4,
        list: [
            {
                id: 0,
                category: "PIERRE UNO",
                menuList: [
                    {
                        name: "PIERRE UNO",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                    {
                        name: "Sleep/Lounge",
                        link: ""
                    },
                ]
            },
            {
                id: 1,
                category: "JORDAN CAVALLI",
                menuList: [
                    {
                        name: "JORDAN CAVALLI",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    }
                ]
            },
            {
                id: 2,
                category: "BEYONDSKIN",
                menuList: [
                    {
                        name: "BEYONDSKIN",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    }
                ]
            },
            {
                id: 3,
                category: "RUDES & SON",
                menuList: [
                    {
                        name: "RUDES & SON",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                    {
                        name: "Sleep/Lounge",
                        link: ""
                    },
                ]
            }
        ]
    },
    {
        id: 0,
        menu: "MEN",
        total: 2,
        list: [
            {
                id: 0,
                category: "ARROW APPLE",
                menuList: [
                    {
                        name: "ARROW APPLE",
                        link: ""
                    },
                    {
                        name: "All Products",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                ]
            },
            {
                id: 1,
                category: "STYL",
                menuList: [
                    {
                        name: "STYL ",
                        link: ""
                    },
                    {
                        name: "All Products ",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Pajamas",
                        link: ""
                    },
                    {
                        name: "Nightgowns",
                        link: ""
                    }
                ]
            }
        ]
    }
]

export const DataProducts = [
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "/img/product/1.png"
    },
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "../img/product/2.png"
    },
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "../img/product/3.png"
    },
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "../img/product/4.png"
    }
]

export const DataProduct = [
    {
        id: 0,
        category: "All",
        slug: "all-mens",
        type: "MENS",
        listProduct: [
            {
                productName: "JC Boy",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "/img/jc_boy/1.jpg",
                    "/img/jc_boy/2.jpg",
                    "/img/jc_boy/3.jpg",
                    "/img/jc_boy/4.jpg"
                ],
                productThumbnail: "/img/jc_boy/1.jpg"
            },
            {
                productName: "Pierre Uno",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "/img/pu/pu-boxer1/bx1.jpg",
                    "/img/pu/pu-boxer1/bx2.jpg",
                    "/img/pu/pu-boxer1/bx3.jpg",
                    "/img/pu/pu-boxer1/bx4.jpg",
                    "/img/pu/pu-boxer1/bx5.jpg",
                    "/img/pu/pu-boxer1/bx6.jpg",
                ],
                productThumbnail: "/img/pu/pu-boxer1/bx1.jpg"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-boxer2/bx1.jpg",
                    "../img/pu/pu-boxer2/bx2.jpg",
                    "../img/pu/pu-boxer2/bx3.jpg",
                    "../img/pu/pu-boxer2/bx4.jpg",
                    "../img/pu/pu-boxer2/bx5.jpg",
                    "../img/pu/pu-boxer2/bx6.jpg",
                ],
                productThumbnail: "../img/pu/pu-boxer2/bx1.jpg"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-b01m/img_0021.jpg",
                    "../img/pu/pu-b01m/img_0026.jpg",
                    "../img/pu/pu-b01m/img_0030.jpg",
                    "../img/pu/pu-b01m/img_0032.jpg",
                ],
                productThumbnail: "../img/pu/pu-b01m/img_0021.jpg"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-b05m/img_0089.jpg",
                    "../img/pu/pu-b05m/img_0092.jpg",
                    "../img/pu/pu-b05m/img_0096.jpg",
                    "../img/pu/pu-b05m/img_0097.jpg",
                    "../img/pu/pu-b05m/img_0098.jpg",
                    "../img/pu/pu-b05m/img_0099.jpg",
                ],
                productThumbnail: "../img/pu/pu-b05m/img_0089.jpg"
            },
            {
                productName: "P+1",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/p+1/po-bs01 m_001.jpg",
                    "../img/p+1/po-bs01 m_002.jpg",
                    "../img/p+1/po-bs01 m_003.jpg",
                    "../img/p+1/po-bs01 m_006.jpg",
                    "../img/p+1/PO-BS01 M_007.jpg",
                    "../img/p+1/po-bs01 m_008.jpg",
                ],
                productThumbnail: "../img/p+1/po-bs01 m_001.jpg"
            }
        ]
    },
    {
        id: 0,
        category: "All",
        slug: "all-women",
        type: "WOMEN",
        listProduct: [
            {
                productName: "AA-B01 F (Rio-Best Seller)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.jpg",
                    "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0045.jpg",
                    "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0047.jpg",
                    "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0051.jpg",
                    "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0053.jpg",
                ],
                productThumbnail: "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.jpg"
            },
            {
                productName: "AA-B02 F (Alfa)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/women/AA/AA-B02 F (Alfa)/img_0003.jpg",
                    "../img/women/AA/AA-B02 F (Alfa)/img_0011.jpg",
                    "../img/women/AA/AA-B02 F (Alfa)/img_0012.jpg",
                    "../img/women/AA/AA-B02 F (Alfa)/img_0019.jpg",
                    "../img/women/AA/AA-B02 F (Alfa)/img_0021.jpg",
                    "../img/women/AA/AA-B02 F (Alfa)/img_0027.jpg",
                ],
                productThumbnail: "../img/women/AA/AA-B02 F (Alfa)/img_0003.jpg"
            }, 
            {
                productName: "AA-B04 F (Cotton)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/women/AA/AA-B04 F (Cotton)/img_0157.jpg",
                    "../img/women/AA/AA-B04 F (Cotton)/img_0160.jpg",
                    "../img/women/AA/AA-B04 F (Cotton)/img_0162.jpg",
                    "../img/women/AA/AA-B04 F (Cotton)/img_0165.jpg",
                    "../img/women/AA/AA-B04 F (Cotton)/img_0166.jpg",
                    "../img/women/AA/AA-B04 F (Cotton)/img_0168.jpg",
                ],
                productThumbnail: "../img/women/AA/AA-B04 F (Cotton)/img_0157.jpg"
            }
        ]
    },
    {
        id: 0,
        category: "All",
        slug: "all-teens",
        type: "TEENS",
        listProduct: [
            {
                productName: "AA-S01",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/teens/AA/AA-S01 BM_001.jpg",
                    "../img/teens/AA/AA-S01 BM_002.jpg",
                    "../img/teens/AA/AA-S01 BM_003.jpg",
                    "../img/teens/AA/AA-S01 BM_004.jpg",
                    "../img/teens/AA/AA-S01 BM_005.jpg",
                ],
                productThumbnail: "../img/teens/AA/AA-S01 BM_001.jpg"
            },
            {
                productName: "JC-BR01 T",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.jpg",
                    "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.jpg",
                    "../img/teens/JC-BR01 T/20230909-F JIO(1)  IMG_0498.jpg",
                    "../img/teens/JC-BR01 T/IMG_0495.jpg",
                    "../img/teens/JC-BR01 T/IMG_0501.jpg",
                ],
                productThumbnail: "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.jpg",

            }, 
            {
                productName: "PU-BR02T",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/teens/PU-BR02T/IMG_0037.jpg",
                    "../img/teens/PU-BR02T/IMG_0038.jpg",
                    "../img/teens/PU-BR02T/IMG_0040.jpg",
                    "../img/teens/PU-BR02T/IMG_0044.jpg",
                    "../img/teens/PU-BR02T/IMG_0047.jpg",
                ],
                productThumbnail: "../img/teens/PU-BR02T/IMG_0037.jpg",
            }, 
            {
                productName: "PU-BS01T",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/teens/PU-BS01T/IMG_0104.jpg",
                    "../img/teens/PU-BS01T/IMG_0106.jpg",
                    "../img/teens/PU-BS01T/IMG_0108.jpg",
                ],
                productThumbnail: "../img/teens/PU-BS01T/IMG_0104.jpg",
            }, 
            {
                productName: "PU-BS09T",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/teens/PU-BS09T/IMG_0235.jpg",
                    "../img/teens/PU-BS09T/IMG_0239.jpg",
                    "../img/teens/PU-BS09T/IMG_0246.jpg",
                ],
                productThumbnail: "../img/teens/PU-BS09T/IMG_0235.jpg",
            }, 
        ]
    },
    {
        id: 0,
        category: "All",
        slug: "all-kids",
        type: "KIDS",
        listProduct: [
            {
                productName: "FI-B02 G (CD. Neci)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/2.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/cd2.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0044.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0279.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0281.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0283.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0286.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0288.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0294.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/2.png",
            },  
            {
                productName: "FI-B03 G (CD Animal Cartoon)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0096.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0098.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0333.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0355.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0358.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0360.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0361.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0363.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0369.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
            },  
            {
                productName: "FI-B04 G (CD Dream)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0428.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0430.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0431.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0435.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0436.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.jpg",
            },  
            {
                productName: "FI-B15 G (CD Kimono)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/4.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0260.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0264.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0270.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0272.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/4.png",
            },   
            {
                productName: "FI-B16 G (Girl Only)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/7.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0113.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0344.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0347.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0349.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0352.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0353.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/7.png",
            }, 
            {
                productName: "FI-B17 G (CD Leap Before Sleep)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/3.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0137.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0404.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0406.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0408.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0413.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0414.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/3.png",
            },  
            {
                productName: "FI-B18 G (Little Girl)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/5.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0085.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0086.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0371.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0373.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0375.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0378.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0381.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/5.png",
            },
            {
                productName: "FI-B20 G (CD Animal Face 01)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/1.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/9.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0299.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0303.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0312.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/1.png",
            },
            {
                productName: "FI-B21 G (CD Femmiephant)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/8.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0009.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0016.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0313.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0317.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0318.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0325.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/8.png",
            }, 
            {
                productName: "FI-B26 G (CD Bunny (Best Seller)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0390.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0393.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0394.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0397.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0399.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0401.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
            }, 
            {
                productName: "FI-BP11 G (CD Bow Fuschia)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/6.png",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0336.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0417.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0418.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0423.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0425.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0426.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/6.png",
            },   
            {
                productName: "FI-BR25 G (CD Animal Face 02)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/10.png",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0249.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0251.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0254.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0258.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/10.png",
            },
            {
                productName: "FI-S01 G (Girl Singlet)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0131.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0144.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0265.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0269.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0272.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0274.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0280.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0286.jpg",

                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
            },
            {
                productName: "PU-B02 B (CD Dino)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0146.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0152.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0202.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0204.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0207.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0209.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
            }, 
            {
                productName: "PU-B04 B (CD Safari)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0176.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0177.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0178.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0180.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0184.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0187.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0190.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0192.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
            }, 
            {
                productName: "PU-B05 B (CD Car (Best Seller)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0066.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0074.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0082.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0186.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0191.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0197.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
            }, 
            {
                productName: "PU-B06 B (CD Polos)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0122.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0125.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0127.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0133.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0254.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0255.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0261.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0262.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
            },
            {
                productName: "PU-B08 B (CD Alien)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0094.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0098.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0101.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0106.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0243.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0244.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0252.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0253.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.jpg",
            }, 
            {
                productName: "PU-B10 B (CD Builder)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0211.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0216.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0221.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0223.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0227.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0229.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0231.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0234.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.jpg",
            }, 
            {
                productName: "PU-B13 B (CD Pirates)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0008.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0013.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0017.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0018.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0023.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0231.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0235.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0237.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0241.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.jpg",
            }, 
            {
                productName: "PU-BR12 B (CD Team Cat)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0142.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0149.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0155.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0159.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0169.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0201.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0205.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0211.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0214.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.jpg",
            }, 
            {
                productName: "PU-BR14 B (CD Space Craft)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0034.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0036.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0045.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0049.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0218.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0219.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0225.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0226.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0228.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.jpg",
            },
            {
                productName: "PU-S01 B (Boy_s Singlet)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Green.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/White.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Aqua.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/grey.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/deep yellow.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/sky blue.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0190.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0193.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0195.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0221.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0224.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0240.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0243.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0245.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0265.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0277.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0282.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Green.jpg",
            }, 
        ]
    },
    {
        id: 0,
        category: "Jordan Cavalli",
        slug: "jordan-cavalli",
        type: "MENS",
        listProduct: [
            {
                productName: "JC Boy",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "/img/jc_boy/1.jpg",
                    "/img/jc_boy/2.jpg",
                    "/img/jc_boy/3.jpg",
                    "/img/jc_boy/4.jpg"
                ],
                productThumbnail: "/img/jc_boy/1.jpg"
            },
        ]
    },
    {
        id: 1,
        category: "Pierre Uno",
        slug: "pierre-uno",
        type: "MENS",
        listProduct: [
            {
                productName: "Pierre Uno",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "/img/pu/pu-boxer1/bx1.jpg",
                    "/img/pu/pu-boxer1/bx2.jpg",
                    "/img/pu/pu-boxer1/bx3.jpg",
                    "/img/pu/pu-boxer1/bx4.jpg",
                    "/img/pu/pu-boxer1/bx5.jpg",
                    "/img/pu/pu-boxer1/bx6.jpg",
                ],
                productThumbnail: "/img/pu/pu-boxer1/bx1.jpg"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-boxer2/bx1.jpg",
                    "../img/pu/pu-boxer2/bx2.jpg",
                    "../img/pu/pu-boxer2/bx3.jpg",
                    "../img/pu/pu-boxer2/bx4.jpg",
                    "../img/pu/pu-boxer2/bx5.jpg",
                    "../img/pu/pu-boxer2/bx6.jpg",
                ],
                productThumbnail: "../img/pu/pu-boxer2/bx1.jpg"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-b01m/img_0021.jpg",
                    "../img/pu/pu-b01m/img_0026.jpg",
                    "../img/pu/pu-b01m/img_0030.jpg",
                    "../img/pu/pu-b01m/img_0032.jpg",
                ],
                productThumbnail: "../img/pu/pu-b01m/img_0021.jpg"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-b05m/img_0089.jpg",
                    "../img/pu/pu-b05m/img_0092.jpg",
                    "../img/pu/pu-b05m/img_0096.jpg",
                    "../img/pu/pu-b05m/img_0097.jpg",
                    "../img/pu/pu-b05m/img_0098.jpg",
                    "../img/pu/pu-b05m/img_0099.jpg",
                ],
                productThumbnail: "../img/pu/pu-b05m/img_0089.jpg"
            },
        ]
    },
    {
        id: 2,
        category: "P+1",
        slug: "p+1",
        type: "MENS",
        listProduct: [
            {
                productName: "P+1",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/p+1/po-bs01 m_001.jpg",
                    "../img/p+1/po-bs01 m_002.jpg",
                    "../img/p+1/po-bs01 m_003.jpg",
                    "../img/p+1/po-bs01 m_006.jpg",
                    "../img/p+1/PO-BS01 M_007.jpg",
                    "../img/p+1/po-bs01 m_008.jpg",
                ],
                productThumbnail: "../img/p+1/po-bs01 m_001.jpg"
            }
        ]
    },
    {
        id: 3,
        category: "Arrow Apple",
        slug: "arrow-apple",
        type: "WOMEN",
        listProduct: [
            {
                productName: "AA-B01 F (Rio-Best Seller)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.jpg",
                    "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0045.jpg",
                    "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0047.jpg",
                    "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0051.jpg",
                    "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0053.jpg",
                ],
                productThumbnail: "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.jpg"
            },
            {
                productName: "AA-B02 F (Alfa)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/women/AA/AA-B02 F (Alfa)/img_0003.jpg",
                    "../img/women/AA/AA-B02 F (Alfa)/img_0011.jpg",
                    "../img/women/AA/AA-B02 F (Alfa)/img_0012.jpg",
                    "../img/women/AA/AA-B02 F (Alfa)/img_0019.jpg",
                    "../img/women/AA/AA-B02 F (Alfa)/img_0021.jpg",
                    "../img/women/AA/AA-B02 F (Alfa)/img_0027.jpg",
                ],
                productThumbnail: "../img/women/AA/AA-B02 F (Alfa)/img_0003.jpg"
            }, 
            {
                productName: "AA-B04 F (Cotton)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/women/AA/AA-B04 F (Cotton)/img_0157.jpg",
                    "../img/women/AA/AA-B04 F (Cotton)/img_0160.jpg",
                    "../img/women/AA/AA-B04 F (Cotton)/img_0162.jpg",
                    "../img/women/AA/AA-B04 F (Cotton)/img_0165.jpg",
                    "../img/women/AA/AA-B04 F (Cotton)/img_0166.jpg",
                    "../img/women/AA/AA-B04 F (Cotton)/img_0168.jpg",
                ],
                productThumbnail: "../img/women/AA/AA-B04 F (Cotton)/img_0157.jpg"
            }
        ]
    },
    {
        id: 3,
        category: "Arrow Apple",
        slug: "teens-arrow-apple",
        type: "TEENS",
        listProduct: [
            {
                productName: "AA-S01",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/teens/AA/AA-S01 BM_001.jpg",
                    "../img/teens/AA/AA-S01 BM_002.jpg",
                    "../img/teens/AA/AA-S01 BM_003.jpg",
                    "../img/teens/AA/AA-S01 BM_004.jpg",
                    "../img/teens/AA/AA-S01 BM_005.jpg",
                ],
                productThumbnail: "../img/teens/AA/AA-S01 BM_001.jpg"
            },
        ]
    },
    {
        id: 3,
        category: "Jordan Cavalli Teens",
        slug: "teens-jordan-cavalli",
        type: "TEENS",
        listProduct: [
            {
                productName: "JC-BR01 T",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.jpg",
                    "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.jpg",
                    "../img/teens/JC-BR01 T/20230909-F JIO(1)  IMG_0498.jpg",
                    "../img/teens/JC-BR01 T/IMG_0495.jpg",
                    "../img/teens/JC-BR01 T/IMG_0501.jpg",
                ],
                productThumbnail: "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.jpg",

            }, 
        ]
    },
    {
        id: 3,
        category: "Pierre Uno Teens",
        slug: "teens-pierre-uno",
        type: "TEENS",
        listProduct: [
            {
                productName: "PU-BR02T",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/teens/PU-BR02T/IMG_0037.jpg",
                    "../img/teens/PU-BR02T/IMG_0038.jpg",
                    "../img/teens/PU-BR02T/IMG_0040.jpg",
                    "../img/teens/PU-BR02T/IMG_0044.jpg",
                    "../img/teens/PU-BR02T/IMG_0047.jpg",
                ],
                productThumbnail: "../img/teens/PU-BR02T/IMG_0037.jpg",
            }, 
            {
                productName: "PU-BS01T",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/teens/PU-BS01T/IMG_0104.jpg",
                    "../img/teens/PU-BS01T/IMG_0106.jpg",
                    "../img/teens/PU-BS01T/IMG_0108.jpg",
                ],
                productThumbnail: "../img/teens/PU-BS01T/IMG_0104.jpg",
            }, 
            {
                productName: "PU-BS09T",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/teens/PU-BS09T/IMG_0235.jpg",
                    "../img/teens/PU-BS09T/IMG_0239.jpg",
                    "../img/teens/PU-BS09T/IMG_0246.jpg",
                ],
                productThumbnail: "../img/teens/PU-BS09T/IMG_0235.jpg",
            }, 
        ]
    },
    {
        id: 3,
        category: "Pierre Uno Kid's",
        slug: "kids-pierre-uno",
        type: "KIDS",
        listProduct: [
            {
                productName: "PU-B02 B (CD Dino)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0146.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0152.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0202.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0204.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0207.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0209.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
            }, 
            {
                productName: "PU-B04 B (CD Safari)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0176.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0177.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0178.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0180.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0184.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0187.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0190.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0192.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
            }, 
            {
                productName: "PU-B05 B (CD Car (Best Seller)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0066.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0074.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0082.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0186.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0191.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0197.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
            }, 
            {
                productName: "PU-B06 B (CD Polos)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0122.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0125.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0127.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0133.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0254.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0255.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0261.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0262.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
            },
            {
                productName: "PU-B08 B (CD Alien)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0094.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0098.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0101.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0106.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0243.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0244.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0252.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0253.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.jpg",
            }, 
            {
                productName: "PU-B10 B (CD Builder)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0211.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0216.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0221.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0223.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0227.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0229.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0231.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0234.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.jpg",
            }, 
            {
                productName: "PU-B13 B (CD Pirates)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0008.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0013.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0017.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0018.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0023.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0231.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0235.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0237.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0241.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.jpg",
            }, 
            {
                productName: "PU-BR12 B (CD Team Cat)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0142.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0149.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0155.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0159.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0169.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0201.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0205.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0211.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0214.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.jpg",
            }, 
            {
                productName: "PU-BR14 B (CD Space Craft)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0034.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0036.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0045.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0049.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0218.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0219.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0225.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0226.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0228.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.jpg",
            },
            {
                productName: "PU-S01 B (Boy_s Singlet)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Green.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/White.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Aqua.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/grey.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/deep yellow.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/sky blue.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0190.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0193.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0195.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0221.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0224.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0240.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0243.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0245.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0265.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0277.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0282.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Green.jpg",
            },      
        ]
    },
    {
        id: 3,
        category: "Finy Girls Kid's",
        slug: "kids-finy-girls",
        type: "KIDS",
        listProduct: [
            {
                productName: "FI-B02 G (CD. Neci)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/2.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/cd2.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0044.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0279.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0281.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0283.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0286.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0288.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0294.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/2.png",
            },  
            {
                productName: "FI-B03 G (CD Animal Cartoon)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0096.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0098.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0333.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0355.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0358.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0360.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0361.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0363.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0369.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
            },  
            {
                productName: "FI-B04 G (CD Dream)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0428.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0430.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0431.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0435.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0436.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.jpg",
            },  
            {
                productName: "FI-B15 G (CD Kimono)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/4.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0260.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0264.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0270.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0272.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/4.png",
            },   
            {
                productName: "FI-B16 G (Girl Only)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/7.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0113.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0344.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0347.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0349.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0352.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0353.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/7.png",
            }, 
            {
                productName: "FI-B17 G (CD Leap Before Sleep)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/3.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0137.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0404.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0406.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0408.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0413.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0414.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/3.png",
            },  
            {
                productName: "FI-B18 G (Little Girl)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/5.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0085.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0086.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0371.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0373.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0375.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0378.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0381.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/5.png",
            },
            {
                productName: "FI-B20 G (CD Animal Face 01)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/1.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/9.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0299.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0303.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0312.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/1.png",
            },
            {
                productName: "FI-B21 G (CD Femmiephant)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/8.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0009.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0016.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0313.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0317.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0318.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0325.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/8.png",
            }, 
            {
                productName: "FI-B26 G (CD Bunny (Best Seller)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0390.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0393.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0394.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0397.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0399.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0401.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
            }, 
            {
                productName: "FI-BP11 G (CD Bow Fuschia)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/6.png",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0336.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0417.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0418.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0423.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0425.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0426.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/6.png",
            },   
            {
                productName: "FI-BR25 G (CD Animal Face 02)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/10.png",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0249.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0251.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0254.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0258.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/10.png",
            },
            {
                productName: "FI-S01 G (Girl Singlet)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0131.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0144.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0265.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0269.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0272.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0274.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0280.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0286.jpg",

                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
            },
        ]
    },
]

export const listCategory = [
    {
        productCategory: "MENS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-mens"
            },
            {
                productBrand: "PIERRE UNO",
                link: "/collections/pierre-uno"
            },
            {
                productBrand: "Jordan Cavalli",
                link: "/collections/jordan-cavalli"
            },
            {
                productBrand: "P+1",
                link: "/collections/p+1"
            }
        ]
    },
    {
        productCategory: "WOMEN",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-women"
            },
            {
                productBrand: "Arrow Apple",
                link: "/collections/arrow-apple"
            }
        ]
    },
    {
        productCategory: "TEENS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-teens"
            },
            {
                productBrand: "Arrow Apple",
                link: "/collections/arrow-apple"
            },
            {   productBrand: "Arrow Apple", 
                link: "/collections/teens-arrow-apple" 
            },
            {   productBrand: "Jordan Cavalli", 
                link: "/collections/teens-jordan-cavalli" 
            },
            {   productBrand: "Pierre Uno", 
                link: "/collections/teens-pierre-uno" 
            }
        ]
    },
    {
        productCategory: "KIDS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-kids"
            },
            { productBrand: "Finy Girls", link: "/collections/kids-finy-girls" },
            { productBrand: "Pierre Uno", link: "/collections/kids-pierre-uno" },
        ]
    },
]

export const newJson = [
    {
        category: "MENS",
        productList: [
            {
                category: "all",
                slug: "all",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.jpg",
                            "/img/jc_boy/2.jpg",
                            "/img/jc_boy/3.jpg",
                            "/img/jc_boy/4.jpg"
                        ],
                        productThumbnail: "/img/jc_boy/1.jpg"
                    },
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.jpg",
                            "/img/pu/pu-boxer1/bx2.jpg",
                            "/img/pu/pu-boxer1/bx3.jpg",
                            "/img/pu/pu-boxer1/bx4.jpg",
                            "/img/pu/pu-boxer1/bx5.jpg",
                            "/img/pu/pu-boxer1/bx6.jpg",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-boxer2/bx1.jpg",
                            "../img/pu/pu-boxer2/bx2.jpg",
                            "../img/pu/pu-boxer2/bx3.jpg",
                            "../img/pu/pu-boxer2/bx4.jpg",
                            "../img/pu/pu-boxer2/bx5.jpg",
                            "../img/pu/pu-boxer2/bx6.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-boxer2/bx1.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.jpg",
                            "../img/pu/pu-b01m/img_0026.jpg",
                            "../img/pu/pu-b01m/img_0030.jpg",
                            "../img/pu/pu-b01m/img_0032.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b05m/img_0089.jpg",
                            "../img/pu/pu-b05m/img_0092.jpg",
                            "../img/pu/pu-b05m/img_0096.jpg",
                            "../img/pu/pu-b05m/img_0097.jpg",
                            "../img/pu/pu-b05m/img_0098.jpg",
                            "../img/pu/pu-b05m/img_0099.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-b05m/img_0089.jpg"
                    },
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/po-bs01 m_001.jpg",
                            "../img/p+1/po-bs01 m_002.jpg",
                            "../img/p+1/po-bs01 m_003.jpg",
                            "../img/p+1/po-bs01 m_006.jpg",
                            "../img/p+1/PO-BS01 M_007.jpg",
                            "../img/p+1/po-bs01 m_008.jpg",
                        ],
                        productThumbnail: "../img/p+1/po-bs01 m_001.jpg"
                    }
                ]
            },
            {
                category: "Jordan Cavalli",
                slug: "jordan-cavalli",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.jpg",
                            "/img/jc_boy/2.jpg",
                            "/img/jc_boy/3.jpg",
                            "/img/jc_boy/4.jpg"
                        ],
                        productThumbnail: "/img/jc_boy/1.jpg"
                    },
                ]
            },
            {
                category: "Pierre Uno",
                slug: "pierre-uno",
                listProduct: [
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.jpg",
                            "/img/pu/pu-boxer1/bx2.jpg",
                            "/img/pu/pu-boxer1/bx3.jpg",
                            "/img/pu/pu-boxer1/bx4.jpg",
                            "/img/pu/pu-boxer1/bx5.jpg",
                            "/img/pu/pu-boxer1/bx6.jpg",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-boxer2/bx1.jpg",
                            "../img/pu/pu-boxer2/bx2.jpg",
                            "../img/pu/pu-boxer2/bx3.jpg",
                            "../img/pu/pu-boxer2/bx4.jpg",
                            "../img/pu/pu-boxer2/bx5.jpg",
                            "../img/pu/pu-boxer2/bx6.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-boxer2/bx1.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.jpg",
                            "../img/pu/pu-b01m/img_0026.jpg",
                            "../img/pu/pu-b01m/img_0030.jpg",
                            "../img/pu/pu-b01m/img_0032.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b05m/img_0089.jpg",
                            "../img/pu/pu-b05m/img_0092.jpg",
                            "../img/pu/pu-b05m/img_0096.jpg",
                            "../img/pu/pu-b05m/img_0097.jpg",
                            "../img/pu/pu-b05m/img_0098.jpg",
                            "../img/pu/pu-b05m/img_0099.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-b05m/img_0089.jpg"
                    },
                ]
            },
            {
                category: "P+1",
                slug: "p+1",
                listProduct: [
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/po-bs01 m_001.jpg",
                            "../img/p+1/po-bs01 m_002.jpg",
                            "../img/p+1/po-bs01 m_003.jpg",
                            "../img/p+1/po-bs01 m_006.jpg",
                            "../img/p+1/PO-BS01 M_007.jpg",
                            "../img/p+1/po-bs01 m_008.jpg",
                        ],
                        productThumbnail: "../img/p+1/po-bs01 m_001.jpg"
                    }
                ]
            },
        ]
    },
    {
        category: "WOMEN",
        productList: [
            {
                category: "all",
                slug: "all",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.jpg",
                            "/img/jc_boy/2.jpg",
                            "/img/jc_boy/3.jpg",
                            "/img/jc_boy/4.jpg"
                        ],
                        productThumbnail: "/img/jc_boy/1.jpg"
                    },
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.jpg",
                            "/img/pu/pu-boxer1/bx2.jpg",
                            "/img/pu/pu-boxer1/bx3.jpg",
                            "/img/pu/pu-boxer1/bx4.jpg",
                            "/img/pu/pu-boxer1/bx5.jpg",
                            "/img/pu/pu-boxer1/bx6.jpg",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-boxer2/bx1.jpg",
                            "../img/pu/pu-boxer2/bx2.jpg",
                            "../img/pu/pu-boxer2/bx3.jpg",
                            "../img/pu/pu-boxer2/bx4.jpg",
                            "../img/pu/pu-boxer2/bx5.jpg",
                            "../img/pu/pu-boxer2/bx6.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-boxer2/bx1.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.jpg",
                            "../img/pu/pu-b01m/img_0026.jpg",
                            "../img/pu/pu-b01m/img_0030.jpg",
                            "../img/pu/pu-b01m/img_0032.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b05m/img_0089.jpg",
                            "../img/pu/pu-b05m/img_0092.jpg",
                            "../img/pu/pu-b05m/img_0096.jpg",
                            "../img/pu/pu-b05m/img_0097.jpg",
                            "../img/pu/pu-b05m/img_0098.jpg",
                            "../img/pu/pu-b05m/img_0099.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-b05m/img_0089.jpg"
                    },
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/po-bs01 m_001.jpg",
                            "../img/p+1/po-bs01 m_002.jpg",
                            "../img/p+1/po-bs01 m_003.jpg",
                            "../img/p+1/po-bs01 m_006.jpg",
                            "../img/p+1/PO-BS01 M_007.jpg",
                            "../img/p+1/po-bs01 m_008.jpg",
                        ],
                        productThumbnail: "../img/p+1/po-bs01 m_001.jpg"
                    }
                ]
            },
            {
                category: "Jordan Cavalli",
                slug: "jordan-cavalli",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.jpg",
                            "/img/jc_boy/2.jpg",
                            "/img/jc_boy/3.jpg",
                            "/img/jc_boy/4.jpg"
                        ],
                        productThumbnail: "/img/jc_boy/1.jpg"
                    },
                ]
            },
            {
                category: "Pierre Uno",
                slug: "pierre-uno",
                listProduct: [
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.jpg",
                            "/img/pu/pu-boxer1/bx2.jpg",
                            "/img/pu/pu-boxer1/bx3.jpg",
                            "/img/pu/pu-boxer1/bx4.jpg",
                            "/img/pu/pu-boxer1/bx5.jpg",
                            "/img/pu/pu-boxer1/bx6.jpg",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-boxer2/bx1.jpg",
                            "../img/pu/pu-boxer2/bx2.jpg",
                            "../img/pu/pu-boxer2/bx3.jpg",
                            "../img/pu/pu-boxer2/bx4.jpg",
                            "../img/pu/pu-boxer2/bx5.jpg",
                            "../img/pu/pu-boxer2/bx6.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-boxer2/bx1.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.jpg",
                            "../img/pu/pu-b01m/img_0026.jpg",
                            "../img/pu/pu-b01m/img_0030.jpg",
                            "../img/pu/pu-b01m/img_0032.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.jpg"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b05m/img_0089.jpg",
                            "../img/pu/pu-b05m/img_0092.jpg",
                            "../img/pu/pu-b05m/img_0096.jpg",
                            "../img/pu/pu-b05m/img_0097.jpg",
                            "../img/pu/pu-b05m/img_0098.jpg",
                            "../img/pu/pu-b05m/img_0099.jpg",
                        ],
                        productThumbnail: "../img/pu/pu-b05m/img_0089.jpg"
                    },
                ]
            },
            {
                category: "P+1",
                slug: "p+1",
                listProduct: [
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/po-bs01 m_001.jpg",
                            "../img/p+1/po-bs01 m_002.jpg",
                            "../img/p+1/po-bs01 m_003.jpg",
                            "../img/p+1/po-bs01 m_006.jpg",
                            "../img/p+1/PO-BS01 M_007.jpg",
                            "../img/p+1/po-bs01 m_008.jpg",
                        ],
                        productThumbnail: "../img/p+1/po-bs01 m_001.jpg"
                    }
                ]
            },
        ]
    }
]