import Slider from "react-slick";

const Reviews = () => {
    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        waitForAnimate: true,
        slidesToShow: 1,
        arrow: false,
        autoplaySpeed: 3000,
      };

    return (
        <>
            <div className="leading-7 text-[#1c1c1c] px-12 pb-36">
                <Slider {...settings}>
                    <div className="md:px-[30rem]">
                        <p className="text-center text-[18px] pb-10">Kalo soal kualitas nathalie udah yg the best. Untuk anak nathalie paling cocok. Kualitas produk sangat baik Harga produk sangat baik Kecepatan pengiriman sangat baik Respon penjual sangat baik</p>
                    </div>
                    <div className="md:px-[30rem]">
                        <p className="text-center text-[18px] pb-10">Ternyata ini memang sangat nyaman dipakai, rasanya sejuk dan lembut banget, kalau orang eropa sana ini tuh rasa nya Summery Wing. percaya gak percaya saya pakai ini rasanya seperti tidak pakai underwear loh, tapi posisi rudal tetap aman terkendali.</p>
                    </div>
                    <div className="md:px-[30rem]">
                        <p className="text-center text-[18px] pb-10">Celana dalamnya memang oke. Krn sy sudah 2 kali pesan. Kainnya lembut, "breatheable", dan gak bikin iritasi selangkangan. Keren pokoknya.</p>
                    </div>
                    <div className="md:px-[30rem]">
                        <p className="text-center text-[18px] pb-10">Recommended Brand, Sudah tiga kali pesan disini, untuk pesanan yang sekarang barang lebih cepat sampai dari pesanan yg lain yg dipesan di hari yang sama. Barang sesuai pesanan, baik ukuran dan bahan. Packing super rapih.</p>
                    </div>
                </Slider>
            </div>
        </>      
  );
}

export default Reviews;



