import Slider from "react-slick";
import Promo from "../../../components/organs/promo";
import banner1 from "../../../assets/img/banner1.jpg";
import banner2 from "../../../assets/img/PU Kids - FB Banner 2.jpg";
import banner3 from "../../../assets/img/PU Kids - FB Banner 3.jpg";
import banner4 from "../../../assets/img/PU Kids - FB Banner 4.jpg";

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        lazyLoad: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: true,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    const Carousel = () => {
        return (
            <div>
                <Promo/>
                <Slider {...settings}>
                    <div>
                        <section className={`bg-no-repeat bg-cover w-full`}>
                            <img src={banner1} alt="banner1"/>
                            {/* <div className="px-16">
                                <h6 className="text-[#ffffff] tracking-[0.2rem] text-[12px]">AFFORDABLE . COMFORTABLE . RELIABLE</h6>
                                <h1 className="mb-8 mt-6 font-normal text-4xl text-[#ffffff] tracking-widest">FLYMAN</h1>
                                <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 tracking-widest">
                                    <button className="inline-flex justify-center items-center py-3 px-5 text-center bg-white w-36">
                                        ATASAN
                                    </button>
                                    <button className="inline-flex justify-center items-center py-3 px-5 sm:ms-4 text-center text-[#ffffff] border border-white w-36">
                                        BAWAHAN
                                    </button>  
                                </div>
                            </div> */}
                        </section>
                    </div>
                    <div>
                        <section className={`bg-no-repeat w-full`}><img src={banner2} alt="banner2"/></section>
                    </div>
                    <div>
                        <section className={`bg-no-repeat w-full`}><img src={banner3} alt="banner3"/></section>
                    </div>
                    <div>
                        <section className={`bg-no-repeat w-full`}><img src={banner4} alt="banner4"/></section>
                    </div>
                    {/* <div>
                        <section className="bg-center bg-no-repeat py-56 bg-[url('https://flymannathalie.com/cdn/shop/files/Home_2.jpg?v=1688369277&width=2000')] bg-gray-400 bg-blend-multiply">
                            <div className="px-16">
                                <h6 className="text-[#ffffff] tracking-[0.2rem] text-[12px]">AFFORDABLE . COMFORTABLE . RELIABLE</h6>
                                <h1 className="mb-8 mt-6 font-normal text-4xl text-[#ffffff] tracking-widest">NATHALIE</h1>
                                <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 tracking-widest">
                                    <button className="inline-flex justify-center items-center py-3 px-5 text-center bg-white w-36">
                                        ATASAN
                                    </button>
                                    <button className="inline-flex justify-center items-center py-3 px-5 sm:ms-4 text-center text-[#ffffff] border border-white w-36">
                                        BAWAHAN
                                    </button>  
                                </div>
                            </div>
                        </section>
                    </div> */}
                </Slider>
            </div>
        );
    }

export default Carousel;
