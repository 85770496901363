function Breadcrumb() {
  return (
    <div className="flex justify-left">
        <nav className="text-[11px] tracking-widest">
            <ol className="list-none p-0 inline-flex space-x-2 uppercase">
                <li className="flex items-center">
                    <a href="/" className="text-gray-600 hover:text-black transition-colors duration-300">Home</a>
                    <span className="mx-2">/</span>
                </li>
                <li className="flex items-center">
                    <a href="/" className="text-gray-600 hover:text-black transition-colors duration-300">Shop</a>
                    <span className="mx-2">/</span>
                </li>
                <li className="flex items-center">
                    <a href="/" className="text-gray-600 hover:text-black transition-colors duration-300">Collections</a>
                </li>
            </ol>
        </nav>
    </div>
  );
}

export default Breadcrumb;
