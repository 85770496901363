
const CompanyList = () => {
    return (
        <div className="">
            <div className="text-center">
                <img src="https://cdn.shopify.com/s/files/1/0081/1532/2978/files/logo-06.jpg" alt="broken img"/>
            </div>
        </div>
  );
}

export default CompanyList;


