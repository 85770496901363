import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from './components/Navbar/Navbar';
import { Routes, Route } from "react-router-dom";
import Homepage from './pages/homepage/containers/main';
import Footer from './components/organs/footer';
import Collections from './pages/collections/containers/collections';
import News from './pages/news/containers/news';
import NewsDetail from './pages/newsDetail/containers/newsDetail';
import Details from './pages/details/containers/details';

function App() {
  return (
    <>
    <Navbar/>
        <Routes>
            <Route index element={<Homepage/>} />
            <Route path="/collections/:param" element={<Collections/>} />
            <Route path="/blogs/newsletter" element={<News/>} />
            <Route path="/blogs/newsletter/detail" element={<NewsDetail/>} />
            <Route path="/products/:param" element={<Details/>} />
        </Routes>
    <Footer/>

    </>
  );
}

export default App;
