import Slider from "react-slick";

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        vertical: true,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
            breakpoint: 768,
            settings: {
                arrows: false
            }
            }
        ]
    };

    const Promo = () => {
        return (
            <div className="announcement-bar lg:px-48 md:px-0 sm:px-0 py-2" style={{backgroundColor:"#b8584b"}}>
                <div className="text-center	text-[#ffffff] tracking-wider text-[14px]">
                    <Slider {...settings}>
                        <div>
                            <p>SPECIAL WEBSITE PRICE</p>
                        </div>
                        <div>
                            <p>FREE SHIPPING 200K+ : FN2024</p>
                        </div> 
                    </Slider>
                </div>
            </div>
        );
    }

export default Promo;
