import Cards from "../components/cards";

const news = ["a", "b", "a",]

function News() {
    return (
        <section className="text-gray-600">
            <h1 className="flex justify-center tracking-[0.4rem] text-[35px] text-[#1c1c1c] p-7 mb-8">NEWS & ARTICLE</h1>
            <div className="container px-24 py-24 mx-auto">
                <div className="flex flex-wrap -m-4">
                    {
                        news.map((index) => {
                            return(
                                <div key={index} className="p-8 md:w-1/3">
                                    <Cards/>
                                </div>
                            )  
                        })
                    }
                </div>
            </div>
        </section>
    );
  }
  
  export default News;
  