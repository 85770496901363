import Slider from "react-slick";

var settings = {
    dots: false,
    infinite: false,
    arrow: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 0,
          slidesToScroll: 0,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      }
    ]
  };


const Categories = () => {
    return (
        <div className="px-10 py-5">
            <Slider {...settings}>
                <div className="p-5">
                    <div className="block overflow-hidden relative ">
                        <img 
                            className="hover:scale-125 ease-in duration-[4000ms] brightness-50" 
                            src={"https://flymannathalie.com/cdn/shop/files/Flyman_fabb3429-5080-4249-98e0-01da607c07ee.jpg?v=1688371950&width=1000"} 
                            alt="broken img"
                        />
                    </div>
                    <div className="absolute bottom-20 pl-12">
                        <h1 className="text-[#ffffff] tracking-widest text-2xl pb-5">PRIA</h1>
                        <a href="/collections/all-mens" className="bg-[#1c1c1c] text-[#ffffff] py-2 px-6 tracking-widest">VIEW PRODUCTS</a>
                    </div>
                </div>
                <div className="p-5">
                    <div className="block overflow-hidden relative">
                        <img 
                            className="hover:scale-125 ease-in duration-[4000ms] brightness-50" 
                            src={"https://flymannathalie.com/cdn/shop/files/Nathalie_1.jpg?v=1688372095&width=1000"} 
                            alt="broken img"
                        />
                    </div>
                    <div className="absolute bottom-20 pl-12">
                        <h1 className="text-[#ffffff] tracking-widest text-2xl pb-5">WANITA</h1>
                        <a href="/collections/all-women" className="bg-[#1c1c1c] text-[#ffffff] py-2 px-6 tracking-widest">VIEW PRODUCTS</a>
                    </div>
                </div>
                <div className="p-5">
                    <div className=" block overflow-hidden relative">
                        <img 
                            className="hover:scale-125 ease-in duration-[4000ms] brightness-50" 
                            src={"https://flymannathalie.com/cdn/shop/files/Anak_Kecil.jpg?v=1688372256&width=1000"} 
                            alt="broken img"
                        />
                    </div>
                    <div className="absolute bottom-20 pl-12">
                        <h1 className="text-[#ffffff] tracking-widest text-2xl pb-5">REMAJA</h1>
                        <a href="/collections/all-teens" className="bg-[#1c1c1c] text-[#ffffff] py-2 px-6 tracking-widest">VIEW PRODUCTS</a>
                    </div>
                </div>
                <div className="p-5">
                    <div className=" block overflow-hidden">
                        <img 
                            className="hover:scale-125 ease-in duration-[4000ms] brightness-50" 
                            src={"https://flymannathalie.com/cdn/shop/files/Anak_Kecil_2.jpg?v=1688372255&width=1000"} 
                            alt="broken img"
                        />
                    </div>
                    <div className="absolute bottom-20 pl-12">
                        <h1 className="text-[#ffffff] tracking-widest text-2xl pb-5">ANAK</h1>
                        <a href="/collections/all-kids" className="bg-[#1c1c1c] text-[#ffffff] py-2 px-6 tracking-widest">VIEW PRODUCTS</a>
                    </div>
                </div>
            </Slider>
        </div>
    );
}

export default Categories;
