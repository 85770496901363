

const News = () => {
    const news = ["a", "b", "a",]
    return (
        <section className="pt-10 pb-10 lg:pt-[120px] lg:pb-20 px-10">
            <h2 className="text-center text-3xl tracking-widest text-[#1c1c1c] pb-10">NEWS & ARTICLE</h2>

            <div className="container mx-auto p-10">
                <div className="-mx-4 flex flex-wrap">
                    {news.map((index) => {
                        return(
                            <div key={index} className="w-full md:w-1/2 lg:w-1/3">
                                <div className="mx-auto mb-10 max-w-[370px]">
                                    <div className="mb-4 overflow-hidden rounded">
                                        <img
                                        src="https://cdn.tailgrids.com/2.0/image/application/images/blogs/blog-01/image-01.jpg"
                                        alt="news"
                                        className="w-full"
                                        />
                                    </div>
                                    <div>  
                                        <a
                                            href="/blogs/newsletter/detail"
                                            className="uppercase tracking-widest text-lg"
                                        >
                                            PERTANYAAN SEPUTAR PAKAIAN DALAM YANG MUNGKIN KAMU MALU UNTUK TANYAKAN
                                        </a>
                                        
                                        <p className="text-body-color text-[13px] mt-3 tracking-widest">
                                            Guys! Let’s be real! Sebagai laki-laki, kamu pernah nggak sih kepikiran soal model pakaian dalam yang kamu gunakan? Enggak, ya? Kebanyakan laki-laki menggunakan model pakaian dalam yang sama hampir...
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    )}
                </div>
            </div>

            <div className="flex justify-center pb-24">
                <a href="/blogs/newsletter"><button className="bg-[#1c1c1c] text-[#ffffff] py-2 px-6 tracking-widest">VIEW ALL</button></a>
            </div>

            <hr className="h-px"/>
        </section>
  );
}

export default News;



