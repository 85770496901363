export const links = [
  {
    name: "Men",
    submenu: true,
    sublinks: [
      {
        Head: "MEN",
        sublink: [
          { name: "All Products", link: "/collections/all-mens" },
          { name: "PIERRE UNO", link: "/collections/pierre-uno" },
          { name: "JORDAN CAVALLI", link: "/collections/jordan-cavalli" },
          { name: "P+1", link: "/collections/p+1" },
        ],
      },
    ],
  },
  // {
  //   name: "Men",
  //   submenu: true,
  //   sublinks: [
  //     {
  //       Head: "PIERRE UNO",
  //       sublink: [
  //         { name: "All Products", link: "/collections/pakaian-dalam-pria" },
  //         { name: "New Arrivals", link: "/" },
  //         { name: "Best Sellers", link: "/" },
  //         { name: "Shirts/Tops", link: "/" },
  //         { name: "Underwear", link: "/" },
  //         { name: "Sleep/Lounge", link: "/" },
  //       ],
  //     },
  //     {
  //       Head: "JORDAN CAVALLI",
  //       sublink: [
  //         { name: "All Products", link: "/" },
  //         { name: "New Arrivals", link: "/" },
  //         { name: "Best Sellers", link: "/" },
  //         { name: "Shirts/Tops", link: "/" },
  //         { name: "Underwear", link: "/" },
  //         { name: "Sleep/Lounge", link: "/" },
  //       ],
  //     },
  //     {
  //       Head: "BEYONDSKIN",
  //       sublink: [
  //         { name: "All Products", link: "/" },
  //         { name: "New Arrivals", link: "/" },
  //         { name: "Best Sellers", link: "/" },
  //         { name: "Shirts/Tops", link: "/" },
  //         { name: "Underwear", link: "/" },
  //         { name: "Sleep/Lounge", link: "/" },
  //       ],
  //     },
  //     {
  //       Head: "RUDES & SON",
  //       sublink: [
  //         { name: "All Products", link: "/" },
  //         { name: "New Arrivals", link: "/" },
  //         { name: "Best Sellers", link: "/" },
  //         { name: "Shirts/Tops", link: "/" },
  //         { name: "Underwear", link: "/" },
  //         { name: "Sleep/Lounge", link: "/" },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Women",
    submenu: true,
    sublinks: [
      {
        Head: "Women",
        sublink: [
          { name: "All Products", link: "/collections/all-women" },
          { name: "Arrow Apple", link: "/collections/arrow-apple" },
        ],
      },
    ],
  },
  {
    name: "Teen's",
    submenu: true,
    sublinks: [
      {
        Head: "Teen's",
        sublink: [
          { name: "All Products", link: "/collections/all-teens" },
          { name: "Arrow Apple", link: "/collections/teens-arrow-apple" },
          { name: "Jordan Cavalli", link: "/collections/teens-jordan-cavalli" },
          { name: "Pierre Uno", link: "/collections/teens-pierre-uno" }
        ],
      }
    ],
  },
  {
    name: "Kid's",
    submenu: true,
    sublinks: [
      {
        Head: "Kid's",
        sublink: [
          { name: "All Products", link: "/collections/all-kids" },
          { name: "Finy Girls", link: "/collections/kids-finy-girls" },
          { name: "Pierre Uno", link: "/collections/kids-pierre-uno" },
        ],
      },
    ],
  },
];
