

function Cards() {
    return (
      <div>
            <div className="h-full shadow-cla-blue overflow-hidden">
                <img className="lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100" src="	https://flymannathalie.com/cdn/shop/articles/5_inspirasi.jpg?v=1605577160&width=1600" alt="news"/>
                <div className="py-8">
                    <h1 className="title-font text-xl text-gray-600 pb-6 tracking-[0.2rem]">5 INSPIRASI OOTD KEKINIAN DENGAN TANK TOP</h1>
                    <p className="leading-relaxed mb-3">Tanktop sudah menjadi must have item bagi wanita-wanita yang tinggal di daerah tropis seperti Indonesia. Cuaca yang cenderung panas sepanjang tahun membuat model atasan tanpa lengan ini sangat nyam...</p>
                    <div className="flex items-center flex-wrap ">
                        <a href="/blogs/newsletter/detail" className=" hover:scale-105 drop-shadow-md shadow-cla-blue py-1 rounded-lg">Read More</a>
                    </div>
                </div>
            </div>
      </div>
    );
  }
  
export default Cards;
  