import { DataProducts } from "../../../components/particles/data";

const Products = () => {
    return (
        <>
            <div className="my-8">
                <div className="container mx-auto px-6">
                    <h1 className="mt-3 text-[14px] text-black text-center font-normal tracking-[.20rem]">FEATURED COLLECTION</h1>
                    <h2 className="text-center uppercase text-3xl tracking-widest text-[#1c1c1c]">Best seller</h2>
                    <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
                        {
                            DataProducts.map((data, index) => {
                                return (
                                <div key={index} className="w-full max-w-sm mx-auto">
                                    <div className="flex items-end justify-end w-full bg-cover">
                                        <img src={data.productImage} alt="alt img" />
                                    </div>
                                    <div className="px-5 py-3 text-center">
                                        <h3 className="text-gray-700 text-sm uppercase tracking-widest">FLYMAN KAOS SINGLET PRIA BAJU DALAM COWOK ICE SILK KAUS DALEMAN 1 PCS FMA 3366</h3>
                                        <span className="text-[#b8584b] mt-2">Rp. 1.000.000</span>
                                    </div>
                                </div>)
                            })
                        }  
                    </div>
                </div>
            </div>
            <div className="flex justify-center pb-24 pt-40">
                <button className="bg-[#1c1c1c] text-[#ffffff] py-2 px-6 tracking-widest">VIEW PRODUCTS</button>
            </div>
            <hr className="h-px"/>
        </>      
  );
}

export default Products;



