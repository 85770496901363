import { useState } from "react";
import { Link } from "react-router-dom";

function ProductList({listProduct, listCategory}) {
    const [toggleProduct, setToggleProduct] = useState(false);
    return (
        <div className="">
            <div className="relative pb-20">
                <div className="flex sticky bg-white xl:top-[76px] lg:top-[76px] border-sky-100 tracking-[0.2rem] text-[12px] uppercase">
                    <div className="relative inline-block h-14 border-[1px] items-center justify-center">
                        <button onClick={() => setToggleProduct(!toggleProduct)} className="inline-flex focus:outline-none items-center justify-center w-32 h-14 border-[1px] gap-2">
                            PRODUCT
                            <svg aria-hidden="true" focusable="false" fill="none" width="10" className="icon icon-chevron-down" viewBox="0 0 10 10">
                                <path d="m1 3 4 4 4-4" stroke="currentColor" strokeLinecap="square"></path>
                            </svg>
                        </button>
                        { 
                            toggleProduct && (
                                <div className="origin-top-right absolute w-52 left-0 right-0 mt-2 rounded-md shadow-lg bg-white ">
                                    <div className="py-2 p-2  w-52">
                                        {
                                            listCategory[0].productList.map((data) => {
                                                return (
                                                    <a href={data.link} className="block px-4 py-2 mb-1 text-sm text-gray-700 rounded-md bg-white hover:bg-gray-100" role="menuitem">{data.productBrand}</a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                         }
                    </div>
                    <div className="flex items-center justify-center grow h-14 border-[1px]">
                        {listProduct[0].listProduct.length} products
                    </div>
                    <div className="flex items-center justify-center w-32 h-14 border-[1px] gap-2">
                        Sort By 
                        <svg aria-hidden="true" focusable="false" fill="none" width="10" className="icon icon-chevron-down" viewBox="0 0 10 10">
                            <path d="m1 3 4 4 4-4" stroke="currentColor" strokeLinecap="square"></path>
                        </svg>
                    </div>
                </div>
                <div className="py-8 mb-32">
                    <div className="container mx-auto px-6">
                        <div className="grid gap-6 grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                            {
                                listProduct[0].listProduct.map((data, index) => {
                                    return (
                                        <div key={index} className="w-full max-w-sm mx-auto">
                                            <Link to={`/products/${data.productName}`} state={{ data }}>
                                                <div className="flex items-end justify-end bg-cover">
                                                    <img src={data.productThumbnail} alt="alt img" />
                                                </div>
                                                <div className="px-5 py-3 text-center">
                                                    <h3 className="text-gray-700 text-sm uppercase tracking-widest">{data.productName}</h3>
                                                    <span className="text-[#b8584b] mt-2">{data.productPrice}</span>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default ProductList;
  